import React, { useEffect, useState } from "react";
import Arrow from "../img/arrow.svg"
import "../scss/modail.scss"
import { ButtonCost } from "./button";




export function ModailCost(){
    const [act, stateAct] = useState(false)
    const [id, stateId] = useState(false)

    useEffect(()=>{
        const location = document.location.search
        const idPoster = location.split("=")[1]
        console.log(idPoster)

        if(idPoster === "1"){
            stateId(false)
        }else if(idPoster === "2"){
            stateId(true)
        }else{
            stateId(false)
        }
    
    },[])


    return(
        
        <div className={act? "cost cost__act":"cost"}>
            <div className="cost_title">
                <span>Слава ЗСУ!</span>
            </div>
            <div className={act? "cost_button cost_button__act":"cost_button"}>
                <ButtonCost id="5" href={id? "":"https://buy.stripe.com/00g4iq8LO9wz5BC8xm"}></ButtonCost>
                <ButtonCost id="25" href={id? "":"https://buy.stripe.com/fZe7uC0fi5gj8NOcND"}></ButtonCost>
                <ButtonCost id="50" href={id? "":"https://buy.stripe.com/14kdT04vycILd445lc"}></ButtonCost>
                <ButtonCost id="100" href={id? "":"https://buy.stripe.com/dR6cOW2nqgZ12pq6pi"}></ButtonCost>
                {/* <ButtonCost id="200" href={id? "":"https://buy.stripe.com/9AQ8yGaTW9wz0hi00v"}></ButtonCost> */}
                <ButtonCost id="500" href={id? "":"https://buy.stripe.com/dR67uC7HK4cf9RSdRL"}></ButtonCost>
                <ButtonCost id="1000" href={id? "":"https://buy.stripe.com/fZebKS3ru2479RSaFA"}></ButtonCost>
            </div>
            <div className="cost_less" onClick={()=>{stateAct(!act)}}>
                <img src={Arrow} alt="" className={act? "arrow arrow__act":"arrow"}/>
                <span>{act? "Load less": "Load more"}</span>
            </div>
        </div>
    )
}
