import React, { useEffect, useState } from "react";
import "../scss/content.scss"
import {ModailCost} from "../jsx/modail"
import Girl from "../img/map.png"
import Logo from "../img/logo.png"






export function Content(){
    const [iW,setIW] = useState(window.innerWidth)
    useEffect(()=>{
        window.onresize = ()=>{
            setIW(window.innerWidth)
        }
    })

    return(
        <div className="content">
            <img src={Logo} alt="" className={iW>850? "logo": "logo logo__mini"}/>
            <div className="content_midle">
                
                {
                    iW>850? <img className="img-screen" src={Girl} alt="img" />:""
                }
                <div className="case">
                    <span>Збір коштів на автомобіль для ЗСУ</span>
                    <ModailCost></ModailCost>
                    {
                        iW>850? "":<img className="img-screen" src={Girl} alt="img" />
                    }
                    <span>Ціль: 6000 EUR</span>
                </div>
            </div>
        </div>
    )
}

export function Footer(){
    return(
        <footer className="footer">
            <div className="dop-info">
                <span className="dop-info_title"></span><br />
                <span className="dop-info_sub-title">Наші Козаки, кожен день ризикують своїм життям, захищаючи нас від навали орків, потрібна допомога по забезпеченню швидкого переміщення в зоні бойових дій.</span><br />
                <span className="dop-info_sub-title">Виконуймо завдання по збору коштів на машину для підрозділу розвідки ЗСУ та виконання бойових завдань. </span><br />
                <span className="dop-info_info">Як це працює:</span>
                <ul className="dop-info_list">
                    <li>Знаходимо машину Jeep Cherokee. Саме таку машину очікують наші хлопці. Декілька таких машин знайдено у Польши</li>
                    <li>Збираємо цільову суму</li>
                    <li>Закуповуємо одну з доступних машин, яка буде ще доступна і стан якої буде відповідати умовам </li>
                    <li>Оформлюємо необхідні документи</li>
                    <li>Транспортуємо в Україну</li>
                    <li>Швидко проводимо необхідне ТО</li>
                    <li>Передаємо військовій частині</li>
                </ul>
                <span className="dop-info_info">Участь у програмі приймають:</span>
                <ul className="dop-info_list">
                    <li>Fundacja polski korpus pokoju, Poland</li>
                    <li>Fundacja centrum pomocy humanitarnej, Poland</li>
                    <li>Baidriba “JK Nams”, Latvia</li>
                    <li>Благодійна організація "Благодійний фонд "Новопоміч", Україна</li>
                    <li>Платформа Charity Hero, технологічний партнер</li>
                </ul>
            </div>
            {/* <span className="footer_title">Raised funds will be used to help Ukrainian families affected by the war in Ukraine</span> */}
            <span className="footer_sub-title">©2022 , CO "CF Ukrainian Educational Foundation", Biedrība (BDR) "JK NAMS". All rights reserved.</span>
        </footer>
    )
}
